<template>
    <aside
        id="default-sidebar"
        class="fixed max-h-full top-17 left-0 z-40 w-72 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
    >
        <div
            class="h-full px-10 pt-24 overflow-y-auto bg-white text-gray-900 border-r"
        >
            <RouterLink
                :to="{ name: 'campaign_create' }"
                class="inline-flex items-center px-4 py-3 border border-primary text-center text-xs font-semibold text-primary rounded-lg shadow-sm hover:bg-blue-100"
            >
                <i class="fi fi-rs-pencil translate-y-0.5 mr-2"></i>
                New campaign
            </RouterLink>
            <ul class="space-y-4 font-medium mt-16">
                <SidebarItem
                    v-for="item in menuItems"
                    :key="item.routeName"
                    :titleTKey="item.titleTKey"
                    :icon="item.icon"
                    :routeName="item.routeName"
                />
            </ul>
        </div>
    </aside>
</template>

<script setup lang="ts">
import SidebarItem from './SideBarItem.vue'
import { RouteName } from '@/types'
import { TKey } from '@/i18n'

type MenuItem = {
    titleTKey: TKey
    icon: string
    routeName: RouteName
}

const menuItems: Array<MenuItem> = [
    {
        titleTKey: 'backoffice.navigation.campaigns',
        icon: 'fi-rs-megaphone',
        routeName: 'campaigns_list',
    },
    {
        titleTKey: 'backoffice.navigation.recipients',
        icon: 'fi-rs-users-alt',
        routeName: 'recipients_list',
    },
    {
        titleTKey: 'backoffice.navigation.recipientsList',
        icon: 'fi-rs-rectangle-list',
        routeName: 'rlists_list',
    },
    {
        titleTKey: 'backoffice.navigation.analytics',
        icon: 'fi-rs-chat-arrow-grow',
        routeName: 'analytics',
    },
    {
        titleTKey: 'backoffice.navigation.profile',
        icon: 'fi-rs-file-user',
        routeName: 'profile',
    },
    {
        titleTKey: 'backoffice.navigation.organization',
        icon: 'fi-rs-building',
        routeName: 'organization_admin',
    },
    {
        titleTKey: 'backoffice.navigation.billing',
        icon: 'fi-rs-donate',
        routeName: 'donation_list',
    },
]
</script>

<style>
.active {
    @apply bg-background-1 text-primary;
}
</style>
