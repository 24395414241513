import { StepDefinition } from '@/components/ProgressBar/types'
import { InterpellationMode } from '@/models/campaigns'

export const CREATE_UPDATE_CAMPAIGN_STEP_DEFINITIONS = {
    StepBasicInfo: {
        title: 'backoffice.campaigns.createUpdateCampaign.stepBasicInfoTitle',
        description:
            'backoffice.campaigns.createUpdateCampaign.stepBasicInfoDescription',
    } as StepDefinition,
    StepCampaignContext: {
        title: 'backoffice.campaigns.createUpdateCampaign.stepCampaignContextTitle',
        description:
            'backoffice.campaigns.createUpdateCampaign.stepCampaignContextDescription',
    } as StepDefinition,
    StepInterpellations: {
        title: 'backoffice.campaigns.createUpdateCampaign.stepInterpellationsTitle',
        description:
            'backoffice.campaigns.createUpdateCampaign.stepInterpellationsDescription',
    } as StepDefinition,
    StepCustomQuestions: {
        title: 'backoffice.campaigns.createUpdateCampaign.stepCustomQuestionsTitle',
        description:
            'backoffice.campaigns.createUpdateCampaign.stepCustomQuestionsDescription',
    } as StepDefinition,
}

export const TWITTER_HANDLES_PLACEHOLDER = '@recipients_handles'

export const INTERPELLATION_MODES_ICONS: Record<InterpellationMode, string> = {
    email: 'fi-rs-envelope',
    facebook: 'fi-brands-facebook',
    twitter: 'fi-brands-twitter-alt-circle',
    instagram: 'fi-brands-instagram',
    form: 'fi-rs-file-text',
    review: 'fi-brands-google',
}
