import { defineStore } from 'pinia'
import {
    createCheckoutSessionRequest,
    validateSessionsRequest,
    listSubscriptionsRequest,
    listInvoicesRequest,
    cancerSubscriptionRequest,
} from '@/AppBackoffice/billing/requests'
import { HttpStatusCode } from 'axios'
import { useRequestTracker } from '@/utils/request-tracker'
import { computed } from 'vue'
import { RequestStatus } from '@/types'
import { invoice, subscriptionType } from '@/AppBackoffice/billing/types'

export const useStripeStore = defineStore('Backoffice:Stripe', () => {
    const checkoutSessionRequestTracker = useRequestTracker<{
        url: string
    }>()
    const validateSessionsRequestTracker = useRequestTracker()
    const listSubscriptionsRequestTracker = useRequestTracker<{
        pagination: object
        results: subscriptionType[]
    }>()
    const listInvoicesRequestTracker = useRequestTracker<invoice[]>()
    const cancelSubscriptionRequestTracker = useRequestTracker()

    // ACTIONS
    const createCheckoutSession = async (
        subscriptionValue: string,
        cancelUrl: string
    ) =>
        checkoutSessionRequestTracker.runRequest(
            createCheckoutSessionRequest,
            HttpStatusCode.Ok,
            [subscriptionValue, cancelUrl]
        )

    const validateSessions = async () =>
        validateSessionsRequestTracker.runRequest(
            validateSessionsRequest,
            HttpStatusCode.Ok,
            []
        )

    const listSubscriptions = async () =>
        listSubscriptionsRequestTracker.runRequest(
            listSubscriptionsRequest,
            HttpStatusCode.Ok,
            []
        )

    const listInvoices = async (subscriptionId: string) =>
        listInvoicesRequestTracker.runRequest(
            listInvoicesRequest,
            HttpStatusCode.Ok,
            [subscriptionId]
        )

    const cancelSubscription = async (subscriptionId: string) =>
        cancelSubscriptionRequestTracker.runRequest(
            cancerSubscriptionRequest,
            HttpStatusCode.Ok,
            [subscriptionId]
        )

    // GETTERS
    const checkoutSession = computed(
        checkoutSessionRequestTracker.getLoadedData
    )
    const subscriptionsList = computed(
        listSubscriptionsRequestTracker.getLoadedData
    )
    const invoicesList = computed(listInvoicesRequestTracker.getLoadedData)

    const loading = computed(
        () =>
            checkoutSessionRequestTracker.getRequestStatus() ===
                RequestStatus.IN_PROGRESS ||
            listSubscriptionsRequestTracker.getRequestStatus() ===
                RequestStatus.IN_PROGRESS
    )

    return {
        checkoutSessionRequestTracker,
        validateSessionsRequestTracker,
        listSubscriptionsRequestTracker,
        listInvoicesRequestTracker,
        createCheckoutSession,
        validateSessions,
        listSubscriptions,
        listInvoices,
        cancelSubscription,
        checkoutSession,
        subscriptionsList,
        invoicesList,
        loading,
    }
})
