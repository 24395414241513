import { RouteRecordRaw } from 'vue-router'

import SubscriptionListView from './SubscriptionListView.vue'

const billingRoutes: Array<RouteRecordRaw> = [
    {
        path: 'donation',
        name: 'donation_list',
        component: SubscriptionListView,
    },
]

export default billingRoutes
