<template>
    <form @submit.prevent="onSubmit">
        <ContainerWhite>
            <AVInput
                class="mb-6"
                :label="'Name'"
                v-bind="nameField.props.value"
                v-on="nameField.listeners.value"
            />
            <AVFileDropZone
                name="file"
                :label="t('backoffice.rlists.uploadRList')"
                :fileTypes="['csv']"
                v-model="rlistFile"
                :isImageFile="false"
                @clear="clearFile"
                @update:modelValue="parseColumns"
            />
            <div class="pl-5 mb-3 italic text-gray-500">
                <h4 class="block mb-2 text-[15px] font-medium lg:font-normal">
                    {{ t('backoffice.rlists.columnsTitle') }}
                </h4>
                <p>
                    <span
                        v-for="(value, index) in neededColumns"
                        :key="index"
                        :class="{
                            'font-bold': value[1],
                            'text-green-600':
                                columnsInSelectedFile.length > 0 &&
                                columnsInSelectedFile.includes(value[0]),
                            'text-red-600':
                                columnsInSelectedFile.length > 0 &&
                                value[1] &&
                                !columnsInSelectedFile.includes(value[0]),
                        }"
                        >{{ value[0]
                        }}{{
                            index < neededColumns.length - 1 ? ', ' : ''
                        }}</span
                    >
                </p>
            </div>
            <AVButton type="submit">Upload</AVButton>
        </ContainerWhite>
    </form>
</template>

<script setup lang="ts">
import AVFileDropZone from '@/components/forms/AVFileDropZone.vue'

import { useI18n } from 'vue-i18n'
import ContainerWhite from '@/components/containers/ContainerWhite.vue'
import { ref } from 'vue'
import AVInput from '@/components/forms/AVInput.vue'
import { makeUseField } from '@/utils/forms'
import AVButton from '@/components/forms/AVButton.vue'
import { uploadNewRlist } from './requests'
import { useRequestTracker } from '@/utils/request-tracker'
import { RListSimple } from '@/models/rlists'
import { HttpStatusCode } from 'axios'
import { RequestStatus } from '@/types'
import { useToastsStore } from '@/stores/toasts'
import { useRouter } from 'vue-router'

const router = useRouter()
const toastsStore = useToastsStore()
const useFieldRlit = makeUseField<{ name: string }>()
const nameField = useFieldRlit('name')

const rlistFile = ref<File | null>(null)

const { t } = useI18n()

const requestTracker = useRequestTracker<RListSimple>()

const neededColumns = ref<Array<[string, boolean]>>([
    ['email', true],
    ['first_name', true],
    ['last_name', true],
    ['gender', false],
    ['birth_date', false],
    ['twitter_handle', false],
    ['instagram_handle', false],
    ['facebook_page', false],
    ['photo_url', false],
    ['display_title', false],
    ['constituency', false],
    ['department_name', false],
    ['department_number', false],
    ['party_name', false],
    ['party_short_name', false],
])
const columnsInSelectedFile = ref<string[]>([])

const clearFile = () => {
    rlistFile.value = null
    columnsInSelectedFile.value = []
}

const parseColumns = () => {
    if (rlistFile.value) {
        const reader = new FileReader()
        reader.onload = (e) => {
            const content = e.target?.result as string
            const lines = content.split('\n')
            columnsInSelectedFile.value = lines[0]
                .split(',')
                .filter((column) => column.trim() !== '')
                .map((column) => column.replace('"', ''))
        }
        reader.readAsText(rlistFile.value)
    }
}

const onSubmit = async () => {
    const status = await requestTracker.runRequest(
        uploadNewRlist,
        HttpStatusCode.Created,
        [rlistFile.value!, nameField.value.value!]
    )
    if (status == RequestStatus.SUCCESS) {
        nameField.value.value = undefined
        rlistFile.value = null
        toastsStore.addMessageTKey(
            'backoffice.rlists.messageCreated',
            'success'
        )
        router.push({ name: 'rlists_list' })
        const response: {
            invalid_recipients: string[]
        } = requestTracker.getLoadedData() as unknown as {
            invalid_recipients: string[]
        }
        if (
            response.invalid_recipients &&
            response.invalid_recipients.length > 0
        ) {
            toastsStore.addMessage(
                `Some emails were invalid and not saved : ${response.invalid_recipients.join(
                    ', '
                )}`,
                'danger'
            )
        }
    }
    if (status == RequestStatus.ERROR) {
        const errors = requestTracker.state.value.error?.data as {
            name?: string[]
            file?: string[]
        }
        nameField.errors.value = errors.name ? errors.name : []
        toastsStore.addMessage('Error uploading rlist', 'danger')
        if (errors.file) {
            toastsStore.addMessage(errors.file[0], 'danger')
        }
    }
}
</script>
