<template>
    <div class="max-w-4xl mx-auto p-4">
        <div>
            <div class="text-xl font-semibold mb-3">
                Support us through a monthly donation
            </div>
            <div class="mb-5">
                Activoice is a non-profit project sustained by donations, grants
                and volunteer work. If Activoice is useful to you, we recommend
                a 50 € / month donation to help us maintain and expand the
                service.
            </div>
        </div>
        <!-- Input Section -->
        <div class="md:flex md:gap-8 mb-8">
            <input
                type="number"
                v-model="subscriptionValue"
                placeholder="Enter amount"
                class="md:w-1/6 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
            />
            <AVButton class="md:w-1/3 mt-4 md:mt-0" @click="startSession">
                Donate {{ subscriptionValue }} € / month
            </AVButton>
        </div>
        <LoaderWithOverlay v-if="stripeStore.loading" />
        <div v-else class="space-y-6">
            <div
                v-if="subscriptionsList.length > 0"
                class="text-l font-semibold mb-3"
            >
                Donations & Invoices
            </div>
            <div
                v-for="subscription in subscriptionsList"
                :key="subscription.subscription_id"
                class="bg-white rounded-lg shadow-md p-6 cursor-pointer"
                @click="toggleSubscription(subscription.subscription_id)"
            >
                <!-- Subscription Header -->
                <div class="flex flex-wrap gap-4 items-center">
                    <div class="flex items-center">
                        <span class="text-2xl font-semibold text-blue-600">
                            {{ Number(subscription.value) / 100 }} €
                        </span>
                    </div>
                    <div class="flex items-center">
                        <span
                            class="px-3 py-1 rounded-full text-sm capitalize"
                            :class="{
                                'bg-green-100 text-green-800':
                                    subscription.status === 'active',
                                'bg-red-100 text-red-800':
                                    subscription.status === 'canceled',
                                'bg-yellow-100 text-yellow-800':
                                    subscription.status === 'pending',
                            }"
                        >
                            {{ subscription.status }}
                        </span>
                    </div>
                    <div class="text-gray-600">
                        {{
                            new Date(
                                subscription.start_date
                            ).toLocaleDateString('fr')
                        }}
                    </div>
                    <div class="ml-auto">
                        <svg
                            class="w-6 h-6 transform transition-transform"
                            :class="{
                                'rotate-180': expandedSubscriptions.includes(
                                    subscription.subscription_id
                                ),
                            }"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </div>
                </div>

                <!-- Invoices List and Cancel Button (Expandable) -->
                <div
                    v-if="
                        expandedSubscriptions.includes(
                            subscription.subscription_id
                        )
                    "
                    class="mt-6 space-y-3"
                >
                    <!-- Header with Invoices title and Cancel button -->
                    <div class="flex items-center justify-between mb-2">
                        <h3 class="text-lg font-medium text-gray-700">
                            Invoices
                        </h3>
                        <AVButton
                            v-if="subscription.status === 'active'"
                            button-type="outline-danger"
                            @click.stop="
                                openCancelModal(subscription.subscription_id)
                            "
                        >
                            Cancel Donation
                        </AVButton>
                    </div>
                    <AVLoader
                        v-if="
                            subscription.invoices === undefined &&
                            stripeStore.listInvoicesRequestTracker.getRequestStatus() ==
                                RequestStatus.IN_PROGRESS
                        "
                    />
                    <div
                        v-else
                        v-for="invoice in subscription.invoices"
                        :key="invoice.invoice_id"
                        class="flex flex-wrap items-center gap-4 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                    >
                        <div class="text-sm font-medium text-gray-700">
                            {{ invoice.invoice_id }}
                        </div>
                        <div>
                            <span
                                class="px-2 py-1 rounded-full text-xs capitalize"
                                :class="{
                                    'bg-green-100 text-green-800':
                                        invoice.status === 'paid',
                                    'bg-red-100 text-red-800':
                                        invoice.status === 'failed',
                                    'bg-yellow-100 text-yellow-800':
                                        invoice.status === 'pending',
                                }"
                            >
                                {{ invoice.status }}
                            </span>
                        </div>
                        <div class="ml-auto">
                            <a
                                :href="invoice.hosted_invoice_url"
                                class="text-blue-600 hover:text-blue-800 flex items-center gap-1"
                                target="_blank"
                                rel="noopener noreferrer"
                                @click.stop
                            >
                                <span>View Invoice</span>
                                <svg
                                    class="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Confirmation Modal -->
        <AVModal
            title-t-key="backoffice.billing.confirm_cancellation"
            v-if="showCancelModal"
            @closeModal="showCancelModal = false"
        >
            <template v-slot:content>
                Are you sure you want to cancel this subscription?
                <div class="justify-end">
                    <div class="flex space-x-2 justify-end mt-5">
                        <AVButton
                            button-type="outline-primary"
                            @click="showCancelModal = false"
                        >
                            Cancel
                        </AVButton>
                        <AVButton
                            button-type="outline-danger"
                            @click="confirmCancellation"
                        >
                            Confirm Cancellation
                        </AVButton>
                    </div>
                </div>
            </template>
        </AVModal>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import AVButton from '@/components/forms/AVButton.vue'
import { useStripeStore } from './store'
import { RequestStatus } from '@/types'
import { useToastsStore } from '@/stores/toasts'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import { subscriptionType } from '@/AppBackoffice/billing/types'
import AVLoader from '@/components/AVLoader.vue'
import AVModal from '@/components/AVModal.vue'

const subscriptionValue = ref<string>('50')
const expandedSubscriptions = ref<string[]>([])
const showCancelModal = ref(false)
const subscriptionToCancel = ref<string | null>(null)

const stripeStore = useStripeStore()
const toastsStore = useToastsStore()

const subscriptionsList = ref<subscriptionType[]>([])

const toggleSubscription = async (subscriptionId: string) => {
    const index = expandedSubscriptions.value.indexOf(subscriptionId)
    if (index === -1) {
        expandedSubscriptions.value = [subscriptionId]
        const invoiceRequestStatus =
            await stripeStore.listInvoices(subscriptionId)
        if (invoiceRequestStatus === RequestStatus.SUCCESS) {
            const subscriptionIndex = subscriptionsList.value.findIndex(
                (subscription) =>
                    subscription.subscription_id === subscriptionId
            )
            subscriptionsList.value[subscriptionIndex].invoices =
                stripeStore.invoicesList!
        }
    } else {
        expandedSubscriptions.value.splice(index, 1)
    }
}

const startSession = async () => {
    if (!subscriptionValue.value) {
        toastsStore.addMessage('Please enter a valid amount', 'info')
        return
    }
    const requestStatus = await stripeStore.createCheckoutSession(
        subscriptionValue.value,
        window.location.href
    )
    if (requestStatus === RequestStatus.SUCCESS) {
        window.open(stripeStore.checkoutSession!.url, '_blank')
    } else {
        toastsStore.addMessage(
            "We couldn't create your donation please contact Activoice directly",
            'danger'
        )
    }
}

const openCancelModal = (subscriptionId: string) => {
    subscriptionToCancel.value = subscriptionId
    showCancelModal.value = true
}

const confirmCancellation = async () => {
    if (!subscriptionToCancel.value) return

    const requestStatus = await stripeStore.cancelSubscription(
        subscriptionToCancel.value
    )
    if (requestStatus === RequestStatus.SUCCESS) {
        toastsStore.addMessage('Subscription canceled successfully', 'success')
        listSubscriptions()
    } else {
        toastsStore.addMessage(
            'Failed to cancel subscription, please try again',
            'danger'
        )
    }

    showCancelModal.value = false
    subscriptionToCancel.value = null
}

const listSubscriptions = async () => {
    const subscriptionsRequestStatus = await stripeStore.listSubscriptions()
    if (subscriptionsRequestStatus === RequestStatus.SUCCESS) {
        subscriptionsList.value = stripeStore.subscriptionsList!.results
    }
}

onMounted(async () => {
    await listSubscriptions()
})
</script>
