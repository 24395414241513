import axios from 'axios'
import { API_ROOT_URL } from '@/constants'

export const createCheckoutSessionRequest = (
    subscriptionValue: string,
    cancelUrl: string
) =>
    axios.post(API_ROOT_URL + '/activoice/billing/start-checkout-session/', {
        value: Math.round(Number(subscriptionValue) * 100),
        cancel_url: cancelUrl,
    })

export const validateSessionsRequest = () =>
    axios.get(API_ROOT_URL + '/activoice/billing/validate-checkout-sessions/')

export const listSubscriptionsRequest = () =>
    axios.get(API_ROOT_URL + '/activoice/billing/list-subscriptions/')

export const listInvoicesRequest = (subscriptionId: string) =>
    axios.get(
        API_ROOT_URL +
            '/activoice/billing/list-invoices/' +
            `${subscriptionId}/`
    )

export const cancerSubscriptionRequest = (subscriptionId: string) =>
    axios.post(
        API_ROOT_URL +
            '/activoice/billing/cancel-subscription/' +
            `${subscriptionId}/`
    )
