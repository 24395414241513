import { components } from '@/schema'

export type Organization = components['schemas']['OrganizationAdmin']
export type OrganizationWrite = Omit<Organization, 'id' | 'admins'>

export type LlmProviderType =
    | 'openai'
    | 'mistral'
    | 'anthropic'
    | 'gemini'
    | 'deepseek'

export const LlmProviders: Array<{ value: LlmProviderType; label: string }> = [
    {
        value: 'openai',
        label: 'OpenAI',
    },
    {
        value: 'mistral',
        label: 'Mistral',
    },
    {
        value: 'anthropic',
        label: 'Anthropic',
    },
    {
        value: 'gemini',
        label: 'Gemini',
    },
    {
        value: 'deepseek',
        label: 'DeepSeek',
    },
]

export const LlmModels: {
    [key in LlmProviderType]: Array<{ value: string; label: string }>
} = {
    openai: [
        {
            value: 'gpt-4o',
            label: 'GPT-4o',
        },
    ],
    mistral: [
        {
            value: 'mistral-large-latest',
            label: 'Latest Mistral Large',
        },
    ],
    anthropic: [
        {
            value: 'claude-3-7-sonnet-20250219',
            label: 'Claude 3.7 Sonnet',
        },
        {
            value: 'claude-3-5-sonnet-latest',
            label: 'Claude 3.5 Sonnet',
        },
        {
            value: 'claude-3-opus-latest',
            label: 'Claude 3 Opus',
        },
    ],
    gemini: [
        {
            value: 'gemini-1.5-pro',
            label: 'Gemini 1.5 Pro',
        },
        {
            value: 'gemini-1.5-flash',
            label: 'Gemini 1.5 Flash',
        },
    ],
    deepseek: [
        {
            value: 'deepseek-chat',
            label: 'DeepSeek Chat',
        },
    ],
}
