<template>
    <StepContainerInner class="space-y-4 relative">
        <div
            v-if="placeSearchStore.searchState !== GeolocState.START"
            class="absolute top-5 left-5 cursor-pointer text-primary"
            @click="placeSearchStore.reset"
        >
            <i
                class="fi fi-rs-angle-left mr-1 translate-y-0.5 inline-block text-sm"
            ></i>
            <span class="text-sm">{{ t('user.common.back') }}</span>
        </div>

        <form @submit.prevent="onAddressFormSubmit()" class="space-y-4">
            <StepInstructions class="font-[600]">
                {{ t('user.campaign.chooseplace_enter_your_address') }}
            </StepInstructions>

            <AVInput
                v-model="address"
                name="address"
                prefix-img="rs-marker"
                :placeholder="
                    t('user.campaign.geolocation_address_placeholder')
                "
            />

            <AVButton
                class="w-full !mt-2"
                type="submit"
                :button-type="
                    placeSearchStore.searchState ===
                    GeolocState.SELECT_RECIPIENT
                        ? 'outline-primary'
                        : 'primary'
                "
            >
                {{ t('user.actions.search') }}
            </AVButton>

            <div
                v-if="
                    placeSearchStore.searchState ===
                    GeolocState.VALIDATE_ADDRESS
                "
                class="space-y-4 text-center"
            >
                <div class="text-sm text-gray-800">
                    {{ t('user.campaign.geolocation_select_your_address') }}
                </div>
                <AVSelectButtons
                    class="!mt-2 text-left"
                    :vertical="true"
                    :multiple="false"
                    :options="
                        placeSearchStore.addressSearchResults.map(
                            (address, idx) => ({
                                value: idx,
                                label: address.label,
                            })
                        )
                    "
                    @update:model-value="
                        placeSearchStore.selectAddress($event as number)
                    "
                    v-bind:name="''"
                    :model-value="undefined"
                />
            </div>
        </form>

        <div
            v-if="placeSearchStore.searchState === GeolocState.SELECT_RECIPIENT"
        >
            <div
                v-if="placeSearchStore.businesses.length > 0"
                class="flex flex-col space-y-2"
            >
                <div class="text-sm text-gray-800">
                    {{ t('user.campaign.geolocation_select_recipient') }}
                </div>
                <PlaceSelector
                    v-for="(business, index) in placeSearchStore.businesses"
                    @click="onChooseBusiness(index)"
                    :key="index"
                    :place="business"
                    :campaign-id="props.campaignId"
                    class="cursor-pointer"
                />
            </div>
            <div
                v-else-if="
                    !placeSearchStore.isLoading &&
                    placeSearchStore.businesses.length === 0
                "
                id="no-recipient"
                v-html="
                    store.campaign!.alternative_text_when_no_recipients === ''
                        ? t('user.campaign.geolocation_no_result')
                        : store.campaign!.alternative_text_when_no_recipients
                "
            ></div>
        </div>

        <div
            v-if="placeSearchStore.isLoading"
            class="flex flex-col justify-center items-center"
        >
            <AVLoader />
            {{ t('user.campaign.geolocation_loading') }}
        </div>
    </StepContainerInner>
</template>
<script setup lang="ts">
import { onBeforeMount, onMounted, ref, watch } from 'vue'
import { GeolocState } from '@/AppCampaigns/types'
import {
    usePlaceSearchStore,
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '../../stores'
import StepInstructions from '../StepInstructions.vue'
import StepContainerInner from '@/components/containers/StepContainerInner.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVLoader from '@/components/AVLoader.vue'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import { useI18n } from 'vue-i18n'
import PlaceSelector from './PlaceSelector.vue'
import { useToastsStore } from '@/stores/toasts'

const { t } = useI18n()

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const placeSearchStore = usePlaceSearchStore(props.campaignId)
const wizardStore = useSendCampaignWizardStore(props.campaignId)
const store = useSendCampaignStore(props.campaignId)
const usetoastsStore = useToastsStore()

const address = ref<string | null>(null)
const isRequestDone = ref(false)

onMounted(() => {
    placeSearchStore.initGoogleMaps(store.campaign!.google_maps_api_key)
    placeSearchStore.setSearchQuery(store.interpellation!.search_query!)
})

const onAddressFormSubmit = async () => {
    if (!address.value) {
        return
    }
    placeSearchStore.reset()
    try {
        await placeSearchStore.searchAddress(address.value)
        isRequestDone.value = true
    } catch (e) {
        usetoastsStore.addMessage('An error occured, try again.', 'danger')
    }
}

const onChooseBusiness = (index: number) => {
    placeSearchStore.selectBusiness(index)
    wizardStore.goToNextStep()
}

watch(address, () => {
    if (isRequestDone.value) {
        isRequestDone.value = false
    }
})

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
})
</script>
<style>
#no-recipient > p a {
    text-decoration: underline !important;
    color: var(--color-primary) !important;
}
</style>
