<template>
    <div class="relative group">
        <a :href="sharingLink" class="text-primary" target="_blank">
            <slot>Open campaign</slot>
        </a>
        <span
            v-if="wasCopiedToClipboard"
            class="absolute z-20 top-0 left-5 mb-2 px-2 py-1 bg-gray-100 text-black text-xs rounded-md shadow-lg whitespace-nowrap"
        >
            The sharable campaign link was copied to your clipboard
        </span>
        <div
            button-type="outline-primary"
            @click="copyURL()"
            class="inline-block mx-2 mb-2 px-1 py-1"
        >
            <i class="fi fi-rs-link-alt text-primary cursor-pointer"></i>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { API_ROOT_URL } from '@/constants'

interface Props {
    campaignId: string
    campaignSlug?: string | null
}

const props = defineProps<Props>()

// Computed campaignId based on slug or id
const campaignLinkId = computed(() =>
    props.campaignSlug ? props.campaignSlug : props.campaignId
)

const sharingLink = computed(() => {
    return `${API_ROOT_URL}/activoice/campaigns/sharing/${campaignLinkId.value}/`
})

const wasCopiedToClipboard = ref(false)

const copyURL = async () => {
    await navigator.clipboard.writeText(sharingLink.value)
    wasCopiedToClipboard.value = true
    setTimeout(() => {
        wasCopiedToClipboard.value = false
    }, 1000)
}
</script>
