import { InterpellationMode } from '@/models/campaigns'
import { CustomQuestion } from '@/models/custom-questions'
import * as Yup from 'yup'

export type CustomQuestionPath = `customQuestion.${number}`

const senderValidatorShape = {
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
}

const makeCustomQuestionsValidatorShape = (
    customQuestions: Array<CustomQuestion>
) =>
    customQuestions.reduce(
        (validatorShape, question, index) => {
            const key: CustomQuestionPath = `customQuestion.${index}`
            if (question.required) {
                if (question.type === 'select') {
                    validatorShape[key] = Yup.string().oneOf(
                        question.options.map((option) => option.prompt),
                        'user.forms.field_required'
                    )
                } else {
                    validatorShape[key] = Yup.string().required(
                        'user.forms.field_required'
                    )
                }
            } else {
                validatorShape[key] = Yup.string()
            }
            return validatorShape
        },
        {} as Record<CustomQuestionPath, Yup.StringSchema>
    )

export const makeSenderQuestionsValidator = (
    interpellationMode: InterpellationMode,
    customQuestions: Array<CustomQuestion>
) =>
    Yup.object().shape({
        ...(interpellationMode === 'email' ? senderValidatorShape : {}),
        ...makeCustomQuestionsValidatorShape(customQuestions),
    })

export const buildCustomQuestionPath = (index: number): CustomQuestionPath =>
    `customQuestion.${index}`
