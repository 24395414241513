import { RouteRecordName, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/AppBackoffice/user/stores'
import recipientsRoutes from '@/AppBackoffice/recipients/routes'
import rListsRoutes from '@/AppBackoffice/rlists/routes'
import campaignsRoutes from '@/AppBackoffice/campaigns/routes'
import organizationRoutes from '@/AppBackoffice/organizations/routes'
import { userRoutes, anonymousUserRoutes } from './user/routes'
import BackofficePage from '@/AppBackoffice/components/BackofficePage/BackofficePage.vue'
import generatedContentsRoutes from './generated-contents/routes'
import billingRoutes from './billing/routes'

const publicRoutesNames: Array<string | null | undefined | RouteRecordName> = [
    'login',
    'start-reset',
    'reset-password',
]

const entryPoint: RouteRecordRaw = {
    path: '',
    beforeEnter: (to, _, next) => {
        if (useAuthStore().isLoggedIn || publicRoutesNames.includes(to.name)) {
            next()
        } else {
            next({ name: 'login', query: { next: to.fullPath } })
        }
    },
    children: [
        ...anonymousUserRoutes,
        {
            path: '',
            component: BackofficePage,
            children: [
                {
                    path: '',
                    redirect: { name: 'campaigns_list' },
                },
                ...campaignsRoutes,
                ...recipientsRoutes,
                ...rListsRoutes,
                ...userRoutes,
                ...generatedContentsRoutes,
                ...organizationRoutes,
                ...billingRoutes,
            ],
        },
    ],
}

export default entryPoint
