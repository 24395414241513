<template>
    <div
        class="flex justify-center items-center text-center h-screen bg-gray-100"
    >
        <div class="w-full md:w-3/12 px-5 mt-[-10%]">
            <h2
                class="text-3xl font-light mt-7 mb-3 text-center antialiased text-gray-800"
            >
                Activoice
            </h2>
            <FadingSeparator />
            <br />
            <form @submit.prevent="submit" class="flex flex-col">
                <div class="form-fields text-left">
                    <AVInput
                        :name="'username'"
                        label="Username"
                        type="text"
                        v-model="username"
                        placeholder="Enter your username"
                    />
                    <AVInput
                        :name="'password'"
                        class="mt-3"
                        label="Password"
                        type="password"
                        v-model="password"
                        placeholder="Enter your password"
                    />
                </div>
                <AVButton class="mt-5" type="submit" v-show="!authStore.isBusy"
                    >Log in</AVButton
                >
                <div class="flex flex-col lg:flex-row justify-center">
                    <AVButton
                        class="mt-5"
                        button-type="link"
                        @click="router.push({ name: 'start-reset' })"
                        v-show="!authStore.isBusy"
                        >Forgot Password ?</AVButton
                    >
                </div>
                <AVLoader v-show="authStore.isBusy" :size="58"></AVLoader>
            </form>
            <div>
                <p class="mt-10 text-sm text-gray-600">
                    Interested in using Activoice for your association?
                    <a
                        href="https://forms.gle/xAiGUwd3gkKshUz6A"
                        target="_blank"
                        class="text-blue-500"
                        >Apply here.</a
                    >
                </p>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { RequestStatus } from '@/types'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from './stores'
import FadingSeparator from '@/components/FadingSeparator.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVLoader from '@/components/AVLoader.vue'
import { useToastsStore } from '@/stores/toasts'
const toastsStore = useToastsStore()

interface Props {
    next?: string
}

const props = defineProps<Props>()

const router = useRouter()
const authStore = useAuthStore()

const username = ref('')
const password = ref('')

const submit = async () => {
    const requestStatus = await authStore.login(username.value, password.value)
    if (requestStatus === RequestStatus.SUCCESS) {
        toastsStore.addMessageTKey('backoffice.user.loginSuccess', 'success')
        if (props.next) {
            router.push({ path: props.next })
        } else {
            router.push({ name: 'campaigns_list' })
        }
    } else {
        toastsStore.addMessageTKey('backoffice.user.loginFailed', 'danger')
    }
}
</script>
