<template>
    <form id="campaign-context-form" @submit.prevent="onSubmit">
        <ContainerWhite>
            <div class="w-full md:w-8/12">
                <AVTextarea
                    class="mb-5"
                    v-bind="campaignContextField.props.value"
                    v-on="campaignContextField.listeners.value"
                    :label="t('backoffice.campaigns.campaignContext')"
                    :rows="8"
                    placeholder="backoffice.campaigns.campaignContextPlaceholder"
                />
                <AVTextarea
                    class=""
                    v-bind="campaignFactsField.props.value"
                    v-on="campaignFactsField.listeners.value"
                    :label="t('backoffice.campaigns.keyFacts')"
                    :rows="8"
                    placeholder="backoffice.campaigns.keyFactsPlaceholder"
                />
            </div>
        </ContainerWhite>
        <FixedMenu>
            <SaveButtons
                form-id="campaign-context-form"
                @save="onSave"
                @previous="onClickPreviousStep"
                :has-next="true"
                :has-previous="true"
            />
        </FixedMenu>
    </form>
</template>

<script setup lang="ts">
import { RequestStatus } from '@/types'
import ContainerWhite from '@/components/containers/ContainerWhite.vue'
import { usePredefinedToasts } from '../utils'
import FixedMenu from '../components/FixedMenu.vue'
import SaveButtons from '../components/SaveButtons.vue'
import { makeUseField } from '@/utils/forms'
import { campaignStepContextValidator } from './validation'
import {
    useCampaignBackofficeCrudStore,
    useCampaignCreateUpdateWizardStore,
} from '@/AppBackoffice/campaigns/stores'
import { useForm } from 'vee-validate'
import { CampaignWrite } from '@/models/campaigns'
import { useToastsStore } from '@/stores/toasts'
import { watch } from 'vue'
import AVTextarea from '@/components/forms/AVTextarea.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

interface Props {
    campaignId: string
}

const props = defineProps<Props>()
const { t } = useI18n()

const toastStore = useToastsStore()
const predefinedToasts = usePredefinedToasts()
const wizardStore = useCampaignCreateUpdateWizardStore()
const crudStore = useCampaignBackofficeCrudStore()
const { itemData: currentCampaign } = storeToRefs(crudStore)
const { validate, meta } = useForm({
    // No idea why `toTypedSchema` seems to be needed here.
    // Without it, running validation always returns empty data.
    validationSchema: toTypedSchema(campaignStepContextValidator),
    initialValues: currentCampaign.value,
})
const useCampaignField = makeUseField<CampaignWrite>()
const campaignContextField = useCampaignField('campaign_context')
const campaignFactsField = useCampaignField('campaign_facts')

const onSave = async () => {
    const { valid: isValid } = await validate()
    if (!isValid) {
        toastStore.addMessageTKey(
            'backoffice.messages.validationFailed',
            'danger'
        )
        return false
    }
    let requestStatus: RequestStatus

    const campaignUpdate: CampaignWrite = {
        ...currentCampaign.value!,
        campaign_context: campaignContextField.value.value || '',
        campaign_facts: campaignFactsField.value.value || '',
    }
    requestStatus = await crudStore.runUpdate(props.campaignId, campaignUpdate)

    if (requestStatus === RequestStatus.SUCCESS) {
        predefinedToasts.success()
        wizardStore.setDirtyState(false)
    }

    return requestStatus
}

const onSubmit = async () => {
    if ((await onSave()) === RequestStatus.SUCCESS) {
        wizardStore.goToNextStep()
    }
}

const onClickPreviousStep = async () => {
    const wasSaved = await onSave()
    if (wasSaved) {
        wizardStore.goToPreviousStep()
    }
}

watch(() => meta.value.dirty, wizardStore.setDirtyState)
</script>
