import {
    CampaignWrite,
    campaignValidationBase,
    INTERPELLATION_MODES,
} from '@/models/campaigns'
import * as Yup from 'yup'

export const campaignStepContextValidator: Yup.ObjectSchema<CampaignWrite> =
    Yup.object().shape({
        ...campaignValidationBase,
        interpellation_modes: Yup.array()
            .of(Yup.string().oneOf(INTERPELLATION_MODES).required())
            .required()
            .default(['email'])
            .min(1),
        interpellations: Yup.array().default([]),
        custom_questions: Yup.array().default([]),
        social_networks_image: Yup.string().nullable().default(null),
        campaign_context: Yup.string().default(''),
        campaign_facts: Yup.string().default(''),
    })
